import react from 'react';
import { CodeButtonStyle } from '../styles/styles';
import { ToastContainer, toast } from 'react-toastify';

type Props = {
    content: any;
};

export const CodeButton = ({ content }: Props) => {

    const handleClick = () => {
        if (content.length === 6) {
            navigator.clipboard.writeText(content);
            toast.success("Copied to clipboard");
        }
    };

    return (
        <div>
            <CodeButtonStyle onClick={handleClick}>{content}</CodeButtonStyle>
        </div>
    );
};