import React from "react";
import { FaEyeSlash } from "react-icons/fa";
import { AppBar } from "./components/AppBar";
import { Container, Flex, InputFieldPass, Page, Title } from "./styles/styles";
import { CodeButton } from "./components/CodeButton";
import axios from "axios";
import { useGetCode } from "./hooks/useGetCode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [count, setCount] = React.useState(0);
  const [secret, setSecret] = React.useState("");
  const [type, setType] = React.useState("password");
  const [copied, setCopied] = React.useState(false);
  const [code, setCode] = React.useState("");
  const { otpCode, setotpCode, timeRemaining } = useGetCode(secret);

  // add a hook to get the code when secret is updated

  React.useEffect(() => {
    if (secret.length == 16) {
      axios.get(`https://api2.inqz.net/otp/${secret}`).then((res) => {
        setCode(res.data.code);
        setCount(res.data.timeRemaining);
      });
    }
  }, [secret]);

  React.useEffect(() => {
    if (count > 0) {
      const interval = setInterval(() => {
        setCount(count - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      axios.get(`https://api2.inqz.net/otp/${secret}`).then((res) => {
        setCode(res.data.code);
        setCount(res.data.timeRemaining);
      });
    }
  }, [count]);

  if (timeRemaining === 0) {
    setCount(timeRemaining);
  }
  //setCode(otpCode || "");
  // setCount(timeRemaining);

  return (
    <div
      style={{
        userSelect: "none",
      }}
    >
      <Page>
        <AppBar />
        <form>
          <Title>2FA</Title>
          <div
            style={{
              // center contents
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label htmlFor="secret">Secret</label>
          </div>
          <Flex
            style={{
              // take 50% of the width
              width: "335px",
              // center the element
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InputFieldPass
              style={{ margin: "10px 0px " }}
              id="secret"
              value={secret}
              onChange={(e) => setSecret(e.target.value)}
              maxLength={16}
              type={type}
            />
            <FaEyeSlash
              onClick={() => setType(type === "password" ? "text" : "password")}
              size={30}
              style={{
                cursor: "pointer",
                // center the element
                margin: "0 auto",
                display: "flex",
                backgroundColor: "#272727",
                borderRadius: "5px",
                border: "1px solid #3f3f3f",
                alignItems: "center",
                padding: "18.5px 16px",
              }}
            />
          </Flex>
        </form>
        <Flex justifyContent="center" alignItems="center">
          <h1>Code</h1>
        </Flex>
        <Flex
          justifyContent="center"
          alignItems="center"
        >
          <CodeButton content={code} />
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <h1>Time left</h1>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <h1>{count}</h1>
        </Flex>
        <ToastContainer />
      </Page>
    </div>
  );
}

export default App;
