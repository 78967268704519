import styled, { css } from "styled-components";

export const MainButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  background-color: #2121218d;
  padding: 4px 50px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #58585863;
  margin: 10px 0;
  box-shadow: 0px 5px 0px #00000018;
  cursor: pointer;
`;

type TextButtonProps = Partial<{
  disabled: boolean;
}>;

export const TextButton = styled(MainButton)`
  padding: 18px 28px;
  width: 100%;
  background-color: #272727;
  cursor: pointer;

  ${({ disabled }: TextButtonProps) =>
    disabled &&
    css`
      background-color: #272727;
      cursor: not-allowed;
      color: #a0a0a0;
    `}
`;

export const CodeButtonStyle = styled(MainButton)`
  padding: 18px 28px;
  width: 500px;
  background-color: #272727;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 90px;
  color: #24e691;
  height: 158px;
`;

export const HomePageStyle = styled.div`
  height: 100%;
  padding: 100px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const GuildMenuItemStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  background-color: #252525;
  border-radius: 5px;
  border: 1px solid #ffffff2f;
  margin: 10px 0;
  cursor: pointer;
`;

export const Container = styled.div`
  width: 70%;
  margin: 0 auto;
`;

export const AppBarStyle = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 35px;
  box-sizing: border-box;
  border-bottom: 1px solid #c9c9c921;
`;

export const Title = styled.p`
  font-size: 22px;
  // center it
  text-align: center;
`;

type FlexProps = Partial<{
  alignItems: string;
  justifyContent: string;
  flexDirection: string;
}>;
export const Flex = styled.div<FlexProps>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
`;

export const InputField = styled.input`
  padding: 14px 16px;
  font-size: 16px;
  box-sizing: border-box;
  color: #fff;
  font-family: "DM Sans";
  background-color: #272727;
  border-radius: 5px;
  border: 1px solid #3f3f3f;
  outline: none;
  width: 100%;
  :focus {
    outline: 1px solid #ffffff5a;
  }
  maxlength: 1;
`;

export const InputFieldPass = styled.input`
  padding: 14px 16px;
  font-size: 30px;
  box-sizing: border-box;
  color: #fff;
  font-family: "DM Sans";
  background-color: #272727;
  border-radius: 5px;
  border: 1px solid #3f3f3f;
  outline: none;
  width: 100%;
  :focus {
    outline: 1px solid #ffffff5a;
  }
  maxlength: 1;
`;

export const TextArea = styled.textarea`
    padding; 14px 16px;
    box-sizing: border-box;
    font-size: 16px;
    color: #fff;
    font-family: "DM Sans";
    background-color: #272727;
    border-radius: 5px;
    border: 1px solid #3f3f3f;
    resize: none;
    outline: none;
    width: 100%;
    :focus {
        outline: 1px solid #ffffff5a;
    }
`;

type ButtonProps = {
  variant: "primary" | "secondary" | "tertiary";
  disabled?: boolean;
};

export const Button = styled.button<ButtonProps>`
  padding: 10px 24px;
  outline: none;
  border: none;
  font-size: 16px;
  color: #fff;
  font-family: "DM Sans";
  border-radius: 5px;
  cursor: pointer;
  ${({ variant }) =>
    variant === "primary" &&
    css`
      background-color: #006ed3;
    `}
  ${({ variant }) =>
    variant === "secondary" &&
    css`
      background-color: #3d3d3d;
    `}
    ${({ variant }) =>
    variant === "tertiary" &&
    css`
      background-color: #292929;
    `}
    ${({ disabled }) =>
    disabled === true &&
    css`
      background-color: #3d3d3d;
      cursor: not-allowed;
    `}
`;

export const Page = styled.div`
  padding: 50px;
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 18px;
  font-family: "DM Sans";
  background-color: inherit;
  padding: 12px 16px;
  border: 1px solid #3f3f3f;
  color: #fff;
  border-radius: 5px;
  // change scroll bar color
  &::-webkit-scrollbar {
    display: none;
  }
  & > option {
    background-color: #292929;
  }
`;

export const Overlay = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    background-color: #292929;
    justify-content: center;
    align-items: center;
    position: fixed
    top: 0;
    left: 0;
`;

export const Link = styled.a`
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const CategoryStyle = styled.div`
  border-top: 1px solid #ffffff1b;
  margin-top: 30px;
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

export const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Slider = styled.span`
  position: "absolute";
  cursor: "pointer";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: "#ccc";
  useshadow: false;
`;

export const SwitchContainer = styled.div`
  margin: 0 10px;
`;
