import { useEffect, useState } from "react";
import { getCode } from '../api';

export function useGetCode(thesecret: string) {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [otpCode, setotpCode] = useState<string>("1234567");
    const [timeRemaining, setTimeRemaining] = useState<number>(30);
    useEffect(() => {
        setLoading(true);
        getCode(thesecret)
            .then(({ data }) => {
                setotpCode(String(data.code));
                setTimeRemaining(data.timeRemaining);
            })
            .catch((err) => {
                setError(err);
                console.log(err);
            })
            .finally(() => setLoading(false));
    }, []);

    return {
        error,
        loading,
        otpCode,
        setotpCode,
        timeRemaining,
        setTimeRemaining,
    };
}
