import react from "react";
import { AppBarStyle } from "../styles/styles";


export const AppBar = () => {
    return (
        <AppBarStyle>
            <h1 style={{ fontWeight: "normal", fontSize: "20px" }}>
                Auther by Inqz
            </h1>
            <img
                src="https://bio.inqz.net/woof.png"
                alt="logo"
                height={55}
                width={55}
                style={{ borderRadius: "50%" }}
            />
        </AppBarStyle>
    );
};
